<template>
    <div class="entry_write_wrap">
<!--        응모하기 레이아웃-->
      <div class="entry_title">{{$t('apply')}}</div>
      <div class="entry_sub">{{$t('select_apply')}}</div>
      <div class="search_item">
        <div class="checkbox" :class="{active: termCheck}" @click="changeCheck('termCheck')"
             id="creator" :checked="termCheck"></div>
        <div class="check_term" @click="openModal('terms-modal', 'contestItem')" v-html="$t('contest_application_terms_msg')">
          <span @click="openModal('terms-modal', 'contestItem')">{{$t('terms_apply')}}</span>
          <span v-html="$t('agree_terms2')"></span>
        </div>
      </div>
        <item-list-component :item-type="'entryList'" :member-key="UserInfo.mb_no"></item-list-component>

    </div>
</template>

<script>
import CompetitionListComponent from "@/components/competition/CompetitionListComponent";
import ItemListComponent from "@/components/item/ItemListComponent";
import {mapState} from "vuex";
import EventBus from "@/utils/event-bus";

export default {
    name: "EntryWriteDefaultLayout",
    mixins: [],
    components: {
        ItemListComponent,
        CompetitionListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
          termCheck: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
      openModal(name, type) {
          this.$modal.show(name, {termsType: type});
      },
      changeCheck(dataName) {
        this[dataName] = !this[dataName];
        EventBus.$emit('entryWriteSetData',dataName,this[dataName])
      },
    },
    watch: {},
}
</script>

<style scoped>

</style>
